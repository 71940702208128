:root {
    --primary-color: #1c1678;
    --secondary-color: #8576ff
}

.adminPageContainer {
    background-color: #c0c0c0;
    height: 100%;
    width: 100%;
    display: block
}

.marquee-Finance {
    width: 100%;
    height: 30px;
    background-color: #1C1678;
    color: #fff;
}

.adminTabs {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 25% 75%;
    padding: 5px;
}

.profileInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.profileInfo p {
    font-family: roboto;
    margin-top: 5px;
    margin-bottom: 30px;
}

.tabsChangerWrap,
.tabsContentWrap {
    padding: 5px 10px;
    border: 1px solid;
}

.tabsChangerWrap {
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding-top: 30px;
    gap: 10px;

}

.tabsChangerWrap button {
    padding: 7px;
    border-radius: 15px;
    background-color: var(--secondary-color);
    font-family: poppins;
    color: #fff;
    border: none;
}

.inputStore {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
}

.imageFileUpload {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#imgPrevieeHere {
    max-height: 150px;
}

.saveUploadBlog {
    width: 70px;
    font-size: 1rem;
    font-weight: 500;
    padding: 10px;
    border-radius: 15px;
    border: none;
    position: relative;
    left: -50px;
    align-self: end;
}