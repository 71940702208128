nav {
    display: grid;
    grid-template-columns: 27% 46% 27%;
    /* padding: 0px 20px; */
}

#mobile-nav {
    max-height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
}

#hamburger-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    max-height: 50px;
    height: auto;
}

.nav--child {
    height: 70px;
    width: 100%;
    border: 1px solid #fff;
    box-sizing: border-box;
}

.nav--child img,
#mobile-nav img {
    height: 100%;
}

#mobile-nav img {
    max-height: 60px;
}

#navchild--list {
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.nav-child--children {
    display: flex;
    flex-direction: row;
    height: 50%;
}

.nav-child--children li {
    background-color: red;
    border: 1px solid;
    width: 33.33%;
    justify-content: center;
    color: #fff;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
}

.nav-child--children li a {
    color: #222;
}

.nav-child--children li.active::before {
    content: '';
    position: absolute;
    top: 100%;
    width: 0;
    rotate: 180deg;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid red;
    z-index: 102;

}

#callToAction {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    background-color: green;
}

#callToAction p {
    font-family: poppins;
    font-weight: 500;
    font-size: 1.5rem;
    color: #fff;
}