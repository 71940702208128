.signUp-Component {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 40px;
    background-color: white;
    border: 1px solid skyblue;
    border-radius: 30px;
}

.input-Text-Up-Register-Login {
    width: 350px;
    height: 40px;
    padding: 10px;
    border-radius: 15px;
    border: 1px solid #c0c0c0;
}

.submit--auth {
    background-color: lightblue;
    border: none;
    height: 38px;
    font-family: poppins;
    font-weight: 500;
    border-radius: 15px;
}