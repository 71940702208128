.blogDisplay--main {
    /* height: 100%; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}

.imgBlogFeature {
    background-color: grey;
    width: 100%;
    height: 200px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.blogDisplayHeading {
    font-size: 1.2rem;
    border: 1px solid;
    width: 100%;
    text-align: center;
    padding: 10px;
    /* height: 100%; */
}

.postDiv {
    width: 100%;
    padding: 10px;
}

.blog-single-post {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    align-items: start;
    border: 1px solid;
    border-radius: 15px;
    /* margin: 5px 10px; */
}

.postTitle {
    font-size: 1rem;
    font-weight: 600;
    font-family: roboto;
}

.postDescription {
    font-size: .8rem;
    font-weight: 500;
    font-family: poppins;
}