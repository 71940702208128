.homecontainer {
    display: grid;
    grid-template-columns: 27% 46% 27%;
    /* height: 100vh; */
    /* height: 100%; */
    /* border: 1px solid; */
}

.homecontainerchild {
    height: 100%;
    /* border: 1px solid ; */
}

.homecontainerchild .ul-li {
    /* border: 1px solid; */
    background-color: red;
}

.homecontainerchild .ul-li ul {
    /* border: 1px solid; */
    padding: 10px 0px;
}

.homecontainerchild .ul-li>ul>li:first-child {
    margin-top: 0;
}

.homecontainerchild .ul-li>ul>li:last-child {
    margin-bottom: 0;
}