@font-face {
    font-family: roboto;
    src: url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
}

@font-face {
    font-family: poppins;
    src: url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
}

.slider--wrapper img {
    width: 100%;
    /* height: 100%; */
    /* z-index: 100; */
    position: absolute;
    top: 0px;
    display: flex;
    flex-direction: row;
}

.slider--wrapper {
    overflow: hidden;
    position: relative;
}

.slider-wrapper-main {
    /* display: ; */
    position: relative;
    width: 100%;
}

.slider-wrapper-main button {
    position: absolute;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    z-index: 101;
    top: 45%;
    background-color: #fff;
}

#nextImg {
    right: 0px;
}

#prevImg {
    left: 0px;
}

.ul-heading {
    font-family: roboto !important;
    font-size: 1.2rem;
    display: flex;
    height: 30px;
    width: 100%;
    /* border: 1px solid; */
    background-color: green;
    color: #fff;
    justify-content: center;
    align-items: center;
    /* background-color: ; */
}

.li--child {
    display: flex;
    flex-direction: row;
    border: 1px solid #fff;
    margin: 5px;
    padding: 10px 15px;
    border-radius: 15px;
    background-color: greenyellow;
    color: red;
    font-weight: 400;
}

.li--child a {
    color: red;
    font-weight: 400;
}

.li--child a {
    font-size: 1.1rem;
    font-family: poppins;
}

.displaydetails {
    width: 100%;
}

.headingDisplayDetails {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: poppins;
    font-size: 1.4rem;
    padding: 10px;
    border-top: 1px solid;
    border-bottom: 1px solid;

}

.descDisplayDetails {
    padding: 10px;
    font-family: poppins;
    font-size: 1rem;
}